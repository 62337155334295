import "@css/styles.css"; // Global styles
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JS

// Supabase setup
import supabase from "./supabaseClient";

if (import.meta.env.MODE !== "production") {
  console.log("Supabase initialized:", supabase);
}

// Helper function for session validation
async function validateSession() {
  const userId = sessionStorage.getItem("awarex_user_id");
  const authToken = sessionStorage.getItem("awarex_auth_token");

  if (!userId || !authToken) {
    alert("User session not found. Redirecting to login.");
    window.location.href = "/login.html";
    return false;
  }
  console.log("User session validated.");
  return true;
}

// Normalize the path to match pageModules
function normalizePath(path) {
  // Remove `/src/pages/` and any trailing slashes
  let normalized = path.replace(/^\/src\/pages\//, "/").replace(/\/$/, "");

  // Ensure paths without extensions default to `.html`
  if (!normalized.endsWith(".html")) {
    normalized += ".html";
  }

  return normalized;
}

// Page-specific logic loader
const pageModules = {
  "/registration.html": () => import("@js/user-registration.js"),
  "/partners.html": () => import("@js/partner-registration.js"),
  "/partner-customers.html": () => import("@js/partner-customers.js"),
  "/login.html": () => import("@js/login.js"),
  "/dashboard-partners.html": () => import("@js/dashboard-partners.js"),
  "/bulk-user-addition.html": () => import("@js/bulk-user-addition.js"),
  "/allocate-courses.html": () => import("@js/allocate-courses.js"),
  "/documents.html": () => import("@js/documents.js"),
};

// List of protected paths requiring authentication
const protectedPaths = [
  "/partner-customers.html",
  "/dashboard-partners.html",
  "/bulk-user-addition.html",
  "/allocate-courses.html",
];

document.addEventListener("DOMContentLoaded", async () => {
  console.log("Bootstrap and main script loaded.");
  console.log("Version 1.0.4");

  const currentPath = window.location.pathname;
  const normalizedPath = normalizePath(currentPath);
  console.log("Current path:", currentPath, "Normalized path:", normalizedPath);

  // Validate session for protected pages
  if (protectedPaths.includes(normalizedPath)) {
    const isValidSession = await validateSession();
    if (!isValidSession) return; // Stop processing if session is invalid
  }

  // Dynamically load the module for the current page
  const moduleLoader = pageModules[normalizedPath];
  if (moduleLoader) {
    try {
      console.log(`Loading module for ${normalizedPath}`);
      const module = await moduleLoader();

      // Call the initializer if it exists
      if (typeof module.init === "function") {
        module.init(); // Each module is responsible for its page-specific logic
      }
    } catch (error) {
      console.error(`Error loading module for ${normalizedPath}:`, error);
    }
  } else {
    console.warn(`No module loader found for ${normalizedPath}`);
  }
});
